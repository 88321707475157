export default [
    {
        label: 'Maison',
        link: '/',
    },
    {
        label: 'Shop',
        link: '/shop',
    },
    {
        label: 'Admin',
        link: '/admin',
        isAdmin: true,
    },
];
